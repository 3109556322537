import React from "react";
import loadScript from "load-script";
import { v1 } from "uuid";
import axios from "axios";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";

const createStyle = createUseStyles((theme) => ({
  background: {
    background: "rgba(52,63,83,.368627451)",
    zIndex: 1000000004,
    transition: "all .3s ease-in",
    width: "100vw",
    height: "100vh",
    position: "fixed",
    left: 0,
    top: 0,
  },
  model: {
    zIndex: 1000000005,
    position: "fixed",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: ({ width }) => (width ? width : 750),
    height: ({ height }) => (height ? height : 500),
  },
}));

function Styles(props) {
  const classes = createStyle({
    width: props.width,
    height: props.height,
  });
  return props.children(classes);
}

const BOX_URL = "https://api.box.com/oauth2/token";
const BOX_SDK =
  "https://cdn01.boxcdn.net/platform/elements/16.0.0/en-US/picker.js";
const BOX_STYLE_SHEET =
  "https://cdn01.boxcdn.net/platform/elements/16.0.0/en-US/picker.css";

/**
 * TODO: add refresh token logic
 */
class BoxPicker extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    clientId: PropTypes.string.isRequired,
    clientSecret: PropTypes.string,
    onChoose: PropTypes.func,
    onClose: PropTypes.func,
    onError: PropTypes.func,
    callbackUrl: PropTypes.string,
    extensions: PropTypes.array,
    disabled: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.filePicker = {};
    this.state = { isSdkReady: false, isReady: false, token: null };
  }

  componentDidMount() {
    loadScript(BOX_SDK, () => {
      this.setState((prev) => ({ ...prev, isSdkReady: true }));
    });
  }

  initClient = (token) => {
    if (window.Box && this.state.isSdkReady) {
      var accessToken = token;
      let filePicker = new window.Box.FilePicker();

      // Attach event listener for when the files is selected.
      filePicker.addListener("choose", (items) => {
        filePicker.hide();
        this.setState((prev) => ({ ...prev, isReady: false }));
        if (this.props.onChoose) {
          this.props.onChoose({ token: accessToken, files: items });
        }
      });

      // Attach event listener for when the cancel button is pressed
      filePicker.addListener("cancel", (e) => {
        filePicker.hide();
        this.setState((prev) => ({ ...prev, isReady: false }));
        if (this.props.onClose) {
          this.props.onClose();
        }
      });

      let config = {
        container: ".box-picker-container",
        chooseButtonLabel: "select",
        logoUrl: "https://d268w8hu7j31i6.cloudfront.net/sign/page/logos/box-blue.png"
      };

      if (this.props.extensions) {
        config.extensions = this.props.extensions;
      }

      filePicker.show("0", accessToken, config);
    }
  };

  getToken = async (code) => {
    try {
      let params = new URLSearchParams({
        client_id: this.props.clientId,
        client_secret: this.props.clientSecret,
        code: code,
        grant_type: "authorization_code",
      });
      let options = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };
      let resp = await axios.post(BOX_URL, params, options);

      this.initClient(resp.data.access_token);
      this.setState((prev) => ({
        ...prev,
        isReady: true,
        token: resp.data.access_token,
      }));
    } catch (error) {
      if (this.props.onError) {
        this.props.onError(error);
      }
    }
  };

  authWindow = () => {
    if (this.state.token) {
      this.initClient(this.state.token);
      this.setState((prev) => ({
        ...prev,
        isReady: true,
      }));
      return;
    }
    const left = window.screen.width / 2 - 500 / 2;
    const top = window.screen.height / 2 - 650 / 2;

    let hashkey = `${v1()}`;
    localStorage.setItem("box-init", hashkey);
    let callbackUrl = this.props.callbackUrl || window.location.origin;
    let url = `https://account.box.com/api/oauth2/authorize?response_type=code&client_id=${this.props.clientId}&redirect_uri=${callbackUrl}`;

    window.open(
      url,
      `connect Box`,
      `width=500,height=650,top=${top},left=${left}`
    );

    let isConnectionClosed = (e) => {
      if (e.oldValue !== hashkey) return;
      this.getToken(localStorage.getItem("box-init"));
      window.removeEventListener("storage", storageListener);
    };

    let storageListener = window.addEventListener(
      "storage",
      isConnectionClosed
    );

    return false;
  };

  render() {
    let { isReady } = this.state;
    return (
      <Styles {...this.props}>
        {(classes) => (
          <div>
            <link rel="stylesheet" type="text/css" href={BOX_STYLE_SHEET} />
            {this.state.isReady && <div className={classes.background}></div>}
            <div
              className={`box-picker-container ${classes.model}`}
              style={{ display: !isReady && "none" }}
            ></div>
            <div onClick={() => !this.props.disabled && this.authWindow()}>
              {this.props.children && this.props.children}
              {!this.props.children && <button>Box</button>}
            </div>
          </div>
        )}
      </Styles>
    );
  }
}

export default BoxPicker;
