import React from "react";
import PropTypes from "prop-types";
import loadScript from "load-script";
const ONEDRIVE_SDK_URL = "https://js.live.net/v7.2/OneDrive.js";

const launchOneDrivePicker = (
  clientId,
  multiSelect,
  action,
  viewType,
  advancedOptions
) => {
  return new Promise((resolve, reject) => {
    var odOptions = {
      clientId: clientId,
      action: action || "download",
      multiSelect: multiSelect || true,
      viewType: viewType,
      openInNewWindow: true,
      advanced: advancedOptions || {},
      success: (files) => {
        resolve(files);
      },
      cancel: () => {
        resolve(null);
      },
      error: (e) => {
        reject(e);
      },
    };
    window.OneDrive.open(odOptions);
  });
};

const OneDrivePicker = ({
  clientID,
  onSuccess,
  onCancel,
  onError,
  action,
  multiSelect = false,
  viewType = "files",
  advancedOptions,
  children,
}) => {
  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      loadScript(ONEDRIVE_SDK_URL);
    }
    return () => {
      mounted = false;
    };
  }, [clientID]);
  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        launchOneDrivePicker(
          clientID,
          multiSelect,
          action,
          viewType,
          advancedOptions
        )
          .then((result) => {
            if (result) {
              onSuccess && onSuccess(result);
            } else {
              onCancel && onCancel(null);
            }
          })
          .catch((reason) => {
            console.log(reason);
            onError && onError(reason);
          });
      }}
    >
      {children && children}
      {!children && <button>OneDrive Picker</button>}
    </div>
  );
};

OneDrivePicker.propTypes = {
  clientID: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
  onCancel: PropTypes.func,
  onError: PropTypes.func,
  advancedOptions: PropTypes.object,
  multiSelect: PropTypes.bool,
  action: PropTypes.oneOfType(["share", "download", "query"]),
  viewType: PropTypes.oneOfType(["files", "folders", "all"]),
  children: PropTypes.node,
};

export default OneDrivePicker;
